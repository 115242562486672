/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import {enquireScreen} from 'enquire-js';


import Part1 from './Part1';
import Part2 from './Part2';
import Part3 from './Part3';
import Part4 from './Part4';
import Part5 from './Part5';

import {
    Part1DataSource,
    Part2DataSource,
    Part3DataSource,
    Part4DataSource,
    Part5DataSource,

} from './data.source.js';

import './less/antMotionStyle.less';
import {FloatingWindowProvider} from '../../components/floatingwindow/FloatingWindowContext';
import FloatingWindow from '../../components/floatingwindow/FloatingWindow';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const location = window.location;

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        // 适配手机屏幕;
        enquireScreen((b) => {
            this.setState({isMobile: !!b});
        });
        // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
        /* 如果不是 dva 2.0 请删除 start */
        if (location.port) {
            // 样式 build 时间在 200-300ms 之间;
            setTimeout(() => {
                this.setState({
                    show: true,
                });
            }, 500);
        }
        /* 如果不是 dva 2.0 请删除 end */
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    render() {
        const children = [

            <Part1
                id="Banner0_0"
                key="Banner0_0"
                dataSource={Part1DataSource}
                isMobile={this.state.isMobile}
            />,

            <Part2
                id="Part2"
                key="Teams1_0"
                dataSource={Part2DataSource}
                isMobile={this.state.isMobile}
            />,
            <Part3
                id="Banner0_2"
                key="Banner0_2"
                dataSource={Part3DataSource}
                isMobile={this.state.isMobile}
            />,
            <Part4
                id="Content0_0"
                key="Content0_0"
                dataSource={Part4DataSource}
                isMobile={this.state.isMobile}
            />,
            <Part5
                id="Banner0_1"
                key="Banner0_1"
                dataSource={Part5DataSource}
                isMobile={this.state.isMobile}
            />,

        ];
        return (
            // <FloatingWindowProvider>
            //     <FloatingWindow/>
                <div
                    className="templates-wrapper"
                    ref={(d) => {
                        this.dom = d;
                    }}
                >
                    {children}

                </div>
            // </FloatingWindowProvider>
        );
    }
}
